<template>
  <section class="popular-categories page__block">
    <div class="container">
      <h3 class="title">Популярные категории</h3>
      <div class="popular-categories__inner">
        <div
          class="popular-categories__item"
          :class="{ tobacco: item.id === 8 }"
          v-for="(item, index) in items"
          :key="index"
        >
          <h6 class="popular-categories__title">{{ item.title }}</h6>
          <img
            class="popular-categories__img"
            :src="getImage(item.id)"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppPopularCategories",
  data() {
    return {
      items: [
        {
          title: "Винная карта",
          id: 1,
        },
        {
          title: "Крепкий алкоголь",
          id: 2,
        },
        {
          title: "Пиво и сидр",
          id: 3,
        },
        {
          title: "Аперитивы",
          id: 4,
        },
        {
          title: "Ликеры и настойки",
          id: 5,
        },
        {
          title: "Безалкогольные напитки",
          id: 6,
        },
        {
          title: "Снеки",
          id: 7,
        },
        {
          title: "Табачная продукция",
          id: 8,
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return require("@/" +
        `assets/img/popular-categories/popular-categories-${img}.png`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
